import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Question from './Question';
import questionsData from '../questions.json';

function TestPage() {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [score, setScore] = useState(0);
  const [key, setKey] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const shuffled = [...questionsData.questions].sort(() => 0.5 - Math.random());
    setSelectedQuestions(shuffled.slice(0, 30));
  }, []);

  const handleAnswer = (isCorrect) => {
    if (isCorrect) {
      setScore(score + 1);
    }
  };

  const nextQuestion = () => {
    if (currentQuestionIndex < 29) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setKey(prevKey => prevKey + 1);
    } else {
      navigate('/score', { state: { score } });
    }
  };

  if (selectedQuestions.length === 0) {
    return <div className="p-6 text-center">Loading...</div>;
  }

  return (
    <div className="p-6 space-y-4">
      <h2 className="text-2xl font-bold text-gray-800 text-center">
        Vraag {currentQuestionIndex + 1} / 30
      </h2>
      <Question
        key={key}
        question={selectedQuestions[currentQuestionIndex]}
        onAnswer={handleAnswer}
        onNext={nextQuestion}
      />
    </div>
  );
}

export default TestPage;