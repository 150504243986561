import React from 'react';
import { Link } from 'react-router-dom';
import questionsData from '../questions.json';

function HomePage() {
  return (
    <div className="flex flex-col items-center justify-center p-4 bg-gray-100">
      <div className="w-full max-w-md bg-white rounded-lg shadow-xl overflow-hidden">
        <div className="p-6">
          <h1 className="text-2xl sm:text-3xl font-bold text-gray-800 mb-2">
            Sportschutterslicentie Examen
          </h1>
          <p className="text-sm sm:text-base text-gray-600 mb-4">
            Bereid je voor op het theoretische examen met onze vragenbank.
          </p>
          <div className="bg-blue-50 border-l-4 border-blue-500 p-3 mb-4">
            <p className="text-xs sm:text-sm text-blue-700">
              {questionsData.questions.length} officiële examenvragen beschikbaar
            </p>
          </div>
          <div className="mb-4">
            <h2 className="text-lg font-semibold text-gray-700 mb-2">Hoe het werkt:</h2>
            <ul className="text-xs sm:text-sm text-gray-600 space-y-1 list-disc list-inside">
              <li>Start een oefentest (30 vragen)</li>
              <li>Beantwoord elke vraag</li>
              <li>Bekijk je resultaten</li>
              <li>Herhaal en verbeter</li>
            </ul>
          </div>
          <Link to="/test">
            <button className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded text-sm sm:text-base transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">
              Start Oefentest
            </button>
          </Link>
        </div>
        <div className="px-6 py-3 bg-gray-50 border-t border-gray-200">
          <p className="text-xxs sm:text-xs text-gray-500">
            Disclaimer: Alleen voor studiedoeleinden. Raadpleeg officiële bronnen voor actuele exameneisen.
          </p>
        </div>
      </div>
    </div>
  );
}

export default HomePage;