import React from 'react';
import { Link, useLocation } from 'react-router-dom';

function Header() {
  const location = useLocation();

  return (
    <header className="bg-white shadow-md">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center py-3">
          <div className="flex items-center">
            <Link to="/" className="text-lg sm:text-xl font-bold text-gray-800 hover:text-gray-600">
              Sportschutterslicentie
            </Link>
          </div>
          <nav>
            <ul className="flex space-x-4">
              <li>
                <Link
                  to="/"
                  className={`text-sm sm:text-base text-gray-600 hover:text-gray-800 ${
                    location.pathname === '/' ? 'font-semibold' : ''
                  }`}
                >
                  Home
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
}

export default Header;