import React from 'react';
import { useLocation, Link } from 'react-router-dom';

function ScorePage() {
  const location = useLocation();
  const score = location.state?.score || 0;
  const percentage = ((score / 30) * 100).toFixed(2);

  return (
    <div className="p-6 space-y-6 text-center">
      <h2 className="text-3xl font-bold text-gray-800">Jouw Score</h2>
      <p className="text-5xl font-bold text-blue-600">{score}/30</p>
      <p className="text-2xl text-gray-600">{percentage}%</p>
      <Link to="/">
        <button className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out transform hover:scale-105">
          Terug naar de startpagina
        </button>
      </Link>
    </div>
  );
}

export default ScorePage;