import React, { useState, useEffect } from 'react';

function Question({ question, onAnswer, onNext }) {
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [showNext, setShowNext] = useState(false);
  const [shuffledAnswers, setShuffledAnswers] = useState([]);

  useEffect(() => {
    // Shuffle answers when the question changes
    const answers = Object.entries(question.answers);
    setShuffledAnswers(answers.sort(() => Math.random() - 0.5));
    setSelectedAnswer(null);
    setShowNext(false);
  }, [question]);

  const handleAnswerClick = (answer) => {
    if (selectedAnswer === null) {
      setSelectedAnswer(answer);
      setShowNext(true);
      onAnswer(answer === question.correct_answer);
    }
  };

  const getButtonClass = (answer) => {
    const baseClass = "w-full p-3 rounded-lg font-medium transition duration-300 ease-in-out transform hover:scale-105 ";
    if (selectedAnswer === null) return baseClass + "bg-blue-500 text-white hover:bg-blue-600";
    if (answer === question.correct_answer) return baseClass + "bg-green-500 text-white";
    if (answer === selectedAnswer) return baseClass + "bg-red-500 text-white";
    return baseClass + "bg-gray-300 text-gray-700";
  };

  return (
    <div className="space-y-4 animate-fade-in">
      <h3 className="text-lg font-semibold text-gray-800">{question.question}</h3>
      <div className="space-y-2">
        {shuffledAnswers.map(([key, value]) => (
          <button
            key={key}
            onClick={() => handleAnswerClick(key)}
            className={getButtonClass(key)}
            disabled={selectedAnswer !== null}
          >
            {value}
          </button>
        ))}
      </div>
      {showNext && (
        <button 
          onClick={onNext} 
          className="w-full mt-8 bg-purple-500 hover:bg-purple-600 text-white font-bold py-3 px-4 rounded-lg transition duration-300 ease-in-out transform hover:scale-105"
        >
          Volgende vraag
        </button>
      )}
    </div>
  );
}

export default Question;